import React, { FC, useState, useId, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface INavigationMenuItem {
  link: string;
  item: string | JSX.Element;
  isActive?: boolean;
}

interface INavigationMenuProps {
  trigger: string | JSX.Element;
  items: INavigationMenuItem[];
  renderHidden?: boolean;
  popupAlign?: string;
}

interface IMenuItemProps {
  isActive?: boolean
}

const Wrapper = styled.div`
  position: relative;
`;

const Trigger = styled.button`
  cursor: pointer;
  background: transparent;
  color: ${(props): string => props.theme.palette.black};
  padding: 0;
  border-radius: 3px;
  border: none;
  &:focus.focus-visible {
    outline: 2px solid ${(props): string => props.theme.palette.primary};
    outline-offset: 2px;
  }
`;

interface IPopupProps {
  hidden?: boolean;
  align?: string;
}

const Popup = styled.ul<IPopupProps>`
  position: absolute;
  ${(props) => {
    if (props.align === 'right') {
      return `right: 0;`;
    } else if (props.align === 'left') {
      return `left: 0;`;
    }
  }}
  overflow: hidden;
  list-style-type: none;
  margin: 0;
  padding: 0;
  ${(props) => props.hidden && `display: none;` }
  background: #fff;
  border-radius: 4px;
  box-shadow: 2px 2px 10px 0 rgb(0 0 0 / 40%);
`;

const MenuItem = styled.li<IMenuItemProps>`
  a {
    ${(props) => props.isActive && `color: ${props.theme.palette.primary};` }
  }
`;

const StyledA = styled(Link)`
  display: block;
  padding: 6px;
  color: black;
  text-decoration: none;
  font-size: 14px;
  :hover {
    background-color: ${(props): string => props.theme.palette.hoverGray};
  }
  :focus.focus-visible {
    outline: none;
    box-shadow: 0 0 1px 2px ${({theme}) => theme.palette.primary} inset;
  }
`;

interface IMenuLinkProps extends IWithChildren {
  onKeyDown: (e: React.KeyboardEvent<HTMLAnchorElement>) => void;
  onMouseOver: (index: number) => void;
  onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  isFocused: boolean;
  isShown?: boolean;
  to: string;
}

const MenuLink: FC<IMenuLinkProps> = (
  {
    children,
    to,
    onKeyDown,
    onMouseOver,
    onClick,
    isFocused,
    isShown
  }
): JSX.Element => {

  const aRef = useRef(null);
  useEffect(() => {
    if (isFocused && (isShown || isShown === undefined)) {
      aRef?.current?.focus();
    }
  }, [isFocused, isShown]);

  return (
    <StyledA
      ref={aRef}
      onKeyDown={onKeyDown}
      onMouseOver={onMouseOver}
      onClick={onClick}
      to={to}
      tabIndex={isFocused ? 0 : -1}
      role={'menuitem'}
    >
      {children}
    </StyledA>
  )
};

const NavigationMenu: FC<INavigationMenuProps> = ({
  trigger,
  items,
  renderHidden= true,
  popupAlign,
}): JSX.Element => {

  const [showPopup, setShowPopup] = useState(false);
  const [focusedItem, setFocusedItem] = useState(0);

  useEffect(() => {
    if (showPopup) {
      setFocusedItem(0);
    }
  }, [showPopup]);

  const wrapper = useRef(null);

  const handleClickOutside = (e: MouseEvent): void => {
    const domNode = wrapper?.current;
    const target = e.target as HTMLElement;

    if (!domNode?.contains(target)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    }
  });

  const triggerRef = useRef(null);

  const popupId = useId();

  const clickTrigger = () => {
    setShowPopup(!showPopup);
  }

  const keyDownTrigger = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (!/^ArrowDown$|^ $/.test(e.key)) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    setShowPopup(true);
  };

  const clickListItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
    setShowPopup(false);
    triggerRef.current.focus();
  };

  const keyDownListItem = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
    if(!/^ArrowDown$|^ArrowUp$|^Escape$|^ $/.test(e.key)) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    switch (e.key) {
      case 'ArrowDown':
        setFocusedItem(focusedItem === items.length - 1 ? 0 : focusedItem + 1);
        break;

      case 'ArrowUp':
        setFocusedItem(focusedItem === 0 ? items.length - 1 : focusedItem - 1);
        break;

      case 'Escape':
        setShowPopup(false);
        triggerRef?.current?.focus();
        break;

      case ' ':
        e.target.click();
        break;
    }

  };

  const mouseOverListItem = (index: number) => {
    setFocusedItem(index);
  };

  return (
    <Wrapper
      ref={wrapper}
    >
      <Trigger
        onClick={clickTrigger}
        onKeyDown={keyDownTrigger}
        aria-controls={popupId}
        aria-haspopup
        aria-expanded={showPopup}
        ref={triggerRef}
      >
        {trigger}
      </Trigger>
      { (showPopup || renderHidden) && (
        <Popup
          id={popupId}
          role='menu'
          {...(renderHidden && !showPopup && { hidden: true })}
          {...(popupAlign && { align: popupAlign })}
        >
          { items.map(({
            item,
            link,
            isActive,
          }, index) => {
            return (
              <MenuItem
                role={'none'}
                key={index}
                isActive={isActive}
              >
                <MenuLink
                  onKeyDown={keyDownListItem}
                  onMouseOver={() => mouseOverListItem(index)}
                  onClick={clickListItem}
                  isFocused={index === focusedItem}
                  {...(renderHidden && { isShown: showPopup })}
                  to={link}
                >
                  {item}
                </MenuLink>
              </MenuItem>
            )
          }) }
        </Popup>
      )}
    </Wrapper>
  )
}

export { NavigationMenu };
