/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react';
import styled from 'styled-components';

interface IInputFieldProps {
  isErrorVisible: boolean;
}

const InputField = styled.input<IInputFieldProps>`
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 32px;
  padding: ${(props): string => props.theme.spacing.minor};
  color: ${(props): string => props.theme.palette.black};
  font-family: ${(props): string => props.theme.base.font.family};
  font-size: ${(props): string => `${props.theme.base.font.size}px`};
  line-height: 28px;
  border: ${(props): string => props.theme.forms.border.style};
  border-color: ${(props): string =>
  props.isErrorVisible ? props.theme.palette.error : props.theme.palette.lightGray};
  border-radius: ${({theme}): string => theme.forms.border.radius};
`;

interface IInputFieldProps {
  isErrorVisible: boolean;
}

export { InputField as Input };
