import React, { FC, useId, useRef } from 'react';
import styled from 'styled-components';
import { Checkbox } from './Checkbox';

interface IProps {
  label: string | JSX.Element;
  state?: boolean | string;
  onChanged: (val: boolean | string) => void;
  isMixed?: boolean;
  wrapperStyle?: string;
  checkboxStyle?: string;
  labelStyle?: string;
  labelPosition?: 'right' | 'left';
}

const Wrapper = styled.div`
  display: inline-block;
`

interface IExtraProps {
  labelPosition?: 'right' | 'left';
  styleCustom?: string;
}

const FlexWrapper = styled.div<IExtraProps>`
  display: flex;
  gap: 4px;
  align-items: center;
  ${(props) => props.labelPosition === 'right' ? `` : props.labelPosition === 'left' ? `flex-direction: row-reverse;` : ''}
  ${(props) => props.styleCustom && props.styleCustom}
`;

const LabelWrapper = styled.span<IExtraProps>`
  cursor: default;
  ${(props) => props.labelPosition === 'right' ? `padding-left: 5px;` : props.labelPosition === 'left' ? `padding-right: 5px;` : ''}
  ${(props) => props.styleCustom && props.styleCustom}
  font-size: ${(props): string => `${props.theme.base.font.size}px`}
`;

export const CheckboxLabelled: FC<IProps> = (
  {
    onChanged,
    label,
    state = false,
    isMixed= false,
    wrapperStyle,
    checkboxStyle,
    labelPosition,
    labelStyle,
  }
) => {
  const id = useId();
  const triggerRef = useRef(null);
  return (
    <Wrapper>
      <FlexWrapper
        {...(wrapperStyle && { styleCustom: wrapperStyle })}
        {...(labelPosition && { labelPosition })}
      >
        <Checkbox
          onChanged={onChanged}
          state={state}
          isMixed={isMixed}
          ariaLabelledBy={id}
          triggerRef={triggerRef}
          {...(checkboxStyle && { styleCustom: checkboxStyle })}
        />
        <LabelWrapper
          id={id}
          ref={triggerRef}
          {...(labelPosition && { labelPosition })}
          {...(labelStyle && { styleCustom: labelStyle })}
        >
          {label}
        </LabelWrapper>
      </FlexWrapper>
    </Wrapper>
  );
};
