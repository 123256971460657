import styled from 'styled-components';
import { BaseButton } from './Base';

export const IconButton = styled(BaseButton)`
  height: 24px;
  width: 24px;
  position: relative;
  background: transparent;

  > svg {
    width: 24px;
    height: 24px;
    display: block;
    margin: 0 auto;
  }

  &:hover {
    background: ${(props): string => props.theme.palette.hoverGray};
  }
`;
