import styled, { css } from 'styled-components';
import { BaseButton, baseButtonPaddingStyle } from './Base';

export const secondaryButtonStyle = css`
  ${baseButtonPaddingStyle};
  background: ${(props) => props.theme.palette.secondary};
  border: 1px solid ${(props) => props.theme.palette.lightGray};

  &:hover {
    background-color: ${(props) => props.theme.palette.hoverButtonSecondary};
  }

  &:active {
    background-color: ${(props) => props.theme.palette.activeButtonSecondary};
  }
`;

export const SecondaryButton = styled(BaseButton)`
  ${secondaryButtonStyle};
`;
