import React, {FC} from 'react';
import { CheckboxLabelled } from './CheckboxLabelled';

interface IProps {
  label: string | JSX.Element;
  state?: boolean | string;
  onChanged: (val: boolean | string) => void;
  isMixed?: boolean;
}

export const CheckboxBlockLabelled: FC<IProps> = (
  {
    ...rest
  }
) => {

  return <CheckboxLabelled
    wrapperStyle={'position: relative;'}
    checkboxStyle={'position: absolute; top: 5px; right: 5px;'}
    {...rest}
  />;
};