// eslint-disable-next-line no-use-before-define
import React from 'react';
import styled, { DefaultTheme, css } from 'styled-components';

export interface IButton {
  theme?: DefaultTheme;
}

export const buttonFocusStyle = css`
  &:focus {
    outline: none;
    &.focus-visible {
      outline: ${(props): string => props.theme.accessibility.borderFocusHighlight};
      outline-offset: ${(props): string => props.theme.forms.focused.outlineOffset};
    }
    &:not(:focus-visible) {
      outline: none;
    }
  }
`;

export const baseButtonStyle = css`
  color: ${(props): string => props.theme.buttons.base.text.color};
  background: ${(props): string => props.theme.buttons.base.color};
  font-family: ${(props): string => props.theme.base.font.family};
  font-size: ${(props): number => props.theme.base.font.size}px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  white-space: nowrap;
  text-decoration: none;
  user-select: none;
  ${buttonFocusStyle};
`;

export const baseButtonPaddingStyle = css`
  ${baseButtonStyle};
  padding: 0 ${(props) => props.theme.buttons.primary.padding};
  line-height: 1;
  height: 32px;
`;

const BaseButton = styled.button<IButton>`
  ${baseButtonStyle};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;

  border-radius: ${(props): string => props.theme.base.border.radius};
  line-height: 1;
  height: 32px;
  // transition for outline-offset doesn't work in Safari. Can't use 'all'.
  transition-property: color, background-color, outline-color;
  transition-duration: 0.05s;
  transition-timing-function: linear;

  &[disabled] {
    opacity: ${(props): number => props.theme.buttons.disabled.opacity};
    cursor: default;
  }
`;

// BaseButton.defaultProps = {
//   theme: valamisTheme,
// };

export const PropWrapper = (props: IButton): JSX.Element => <BaseButton {...props} />;

export { BaseButton };
