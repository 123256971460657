// navigation menu redirecting to the the same page but for different existing lang
import React from 'react';
import {
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DEFAULT_LANGUAGE, OMIT_DEFAULT_LANGUAGE } from './const';
import locales from './locales';
import localeNames from './locales/localeNames';
import { NavigationMenu } from './common/Components/Forms';

const getPaths = (location) => {
  const { i18n } = useTranslation();

  const foundLangChunk = location.match(new RegExp(`(?<=^\/)(.*?)(?=(\/|$))`, 'g'))

  const lang = foundLangChunk && locales.includes(foundLangChunk[0])
    ? foundLangChunk[0]
    : DEFAULT_LANGUAGE;

  if (lang !== i18n.resolvedLanguage) {
    i18n.loadLanguages(lang).then(() => {
      i18n.changeLanguage(lang);
    })
  }

  let pathTail = foundLangChunk && locales.includes(foundLangChunk[0])
    ? location.match(new RegExp(`(?<=^\/${lang})(.*)`, 'g'))[0]
    : location;

  if (pathTail.charAt(pathTail.length - 1) === '/') {
    pathTail = pathTail.slice(0, -1);
  }

  return locales.map(locale => {
    let link = `${(!OMIT_DEFAULT_LANGUAGE || (OMIT_DEFAULT_LANGUAGE && locale !== DEFAULT_LANGUAGE)) && `/${locale}` || ''}${pathTail}` || '/';
    if (link.charAt(link.length - 1) !== '/') {
      link = `${link}/`;
    }
    const item = localeNames[locale] || locale;
    return {
      link,
      item,
      isActive: lang === locale
    }
  })
};

const Trigger = styled.div`
  background: white;
  color: black;
  border-radius: 3px;
  padding: 5px;
  :hover {
    background-color: ${(props): string => props.theme.palette.hoverGray};
  }
`;

const RoutedLangMenu = () => {

  const location = useLocation();
  const paths = getPaths(location.pathname);

  const { i18n } = useTranslation();
  const trigger = i18n.resolvedLanguage;

  return (
    <NavigationMenu
      trigger={<Trigger>{trigger}</Trigger>}
      items={paths}
      popupAlign={'right'}
    />
  )
}

const LangMenu = (props) => {
  return (
    <>
      <Routes>
        <Route path="/*" element={<RoutedLangMenu/>}/>
        <Route path="/:lang/*" element={<RoutedLangMenu/>}/>
      </Routes>
    </>
  )
};

export default LangMenu;
