import React, { FC, useCallback, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { baseButtonPaddingStyle } from '../Buttons';

const Wrapper = styled.div`
  display: inline-flex;
  > button:first-child {
    border-radius: 16px 0 0 16px;
    border-width: 2px 1px 2px 2px;
  }
  > button:last-child {
    border-radius: 0 16px 16px 0;
    border-width: 2px 2px 2px 1px;
  }
  > button[aria-pressed=true] {
    border-color: ${({theme}) => theme.palette.primary};
  }
`;

const ToggleButton = styled.button`
  ${baseButtonPaddingStyle};
  background-color: ${({theme}): string => theme.palette.white};
  border-width: 2px 1px 2px 1px;
  border-color: ${(props) => props.theme.palette.lightGray};
  border-style: solid;
  border-radius: 0;
  
  &:hover {
    background-color: ${(props) => props.theme.palette.hoverButtonSecondary};
  }

  &:active {
    background-color: ${(props) => props.theme.palette.activeButtonSecondary};
  }
`;

interface IButton {
  id?: string;
  name: string;
  value?: string;
  title?: string;
  active?: boolean;
}

// type TButton {}

interface IProps {
  buttons: (string | IButton)[],
  radioMode?: boolean;
  focusOnFirstRender?: boolean;
  onChange: (value: string[]) => void;
  // zIndex?: number;
}

export const ButtonToggles: FC<IProps> = (
  {
    buttons,
    radioMode,
    focusOnFirstRender,
    onChange
  }) => {

  const [focusableIndex, setFocusableIndex] = useState(0);
  const [buttonsObj, setButtonsObj] = useState(buttons.map((item, index) => {
    return typeof item === 'string'
      ? {id: String(index), value: item, name: item, active: false}
      : {id: item.id ?? String(index), value: item.value ?? item.name, name: item.name, title: item.title, active: item.active ?? false}
  }));

  const buttonsRef = useRef<(HTMLButtonElement | null)[]>([]);
  const isRendered = useRef<boolean>(false);

  useEffect(() => {
    if (isRendered.current || focusOnFirstRender) {
      buttonsRef.current[focusableIndex].focus();
    }
  }, [focusableIndex]);

  useEffect(() => {
    if (isRendered.current && onChange) {
      const res = buttonsObj.filter((item) => item.active).map((item) => item.value);
      onChange(res);
    }
    isRendered.current = true;
  }, buttonsObj)


  const onButtonClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    console.log(e.target);
  }, []);

  const focusNext = useCallback(() => {
    const maxIndex = buttons.length - 1;
    setFocusableIndex((prevIndex) => {
      if (prevIndex === maxIndex) {
        return 0;
      }
      return prevIndex + 1;
    });
  }, []);

  const focusPrev = useCallback(() => {
    const maxIndex = buttons.length - 1;
    setFocusableIndex((prevIndex) => {
      if (prevIndex === 0) {
        return maxIndex;
      }
      return prevIndex - 1;
    });
  }, []);

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLElement>) => {
    if(!/^ArrowDown$|^ArrowUp$|^ArrowLeft$|^ArrowRight$/.test(e.key)) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    switch (e.key) {
      case 'ArrowDown':
      case 'ArrowRight':
        focusNext();
        break;

      default:
        focusPrev();
    }
  }, []);

  const handleClick = useCallback((item) => {
    setButtonsObj((prevState => {
      const index = prevState.indexOf(item);
      const arr = [...prevState];
      arr[index] = {...item, active: !item.active};
      if (radioMode) {
        if (!arr.some(item => item.active)) {
          arr[index] = item;
        }
      }
      return arr;
    }));
  }, []);

  return <Wrapper
    role='group'
    aria-disabled={false}
    onKeyDown={handleKeyDown}
  >
    {buttonsObj.map((item, index) => (<ToggleButton
      key={item.id}
      tabIndex={focusableIndex === index ? 0 : -1}
      aria-pressed={item.active}
      ref={(el) => buttonsRef.current[index] = el}
      onClick={() => handleClick(item)}
      {...(item.title && { title: item.title})}
    >
      {item.name}
    </ToggleButton>))}
  </Wrapper>;
}

ButtonToggles.defaultProps = {
  radioMode: false,
  focusOnFirstRender: false,
}
