import styled from 'styled-components';

const convertIcon = (color: string): string =>
  btoa(`<svg width="16px" height="16px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
<path d="M 0 8 V 8 H 8 V 0" fill="${color}"/></svg>`);

export const Textarea = styled.textarea<{ height?: string }>`
  display: block;
  height: ${(props): string => props.height || '200px'};
  line-height: 20px;

  padding: 8px;
  appearance: none;
  width: 100%;
  font-family: ${(props): string => props.theme.base.font.family};
  font-size: ${(props): number => props.theme.base.font.size}px;
  color: ${(props): string => props.theme.palette.black};
  box-sizing: border-box;

  border: ${(props): string => props.theme.forms.border.style};
  border-radius: ${(props): string => props.theme.forms.border.radius};

  &:focus {
    border: ${(props): string => props.theme.accessibility.borderFocusHighlight};
    outline: ${(props): number => props.theme.forms.focused.outline};
  }

  &[disabled] {
    opacity: ${(props): number => props.theme.buttons.disabled.opacity};
  }

  &::placeholder {
    color: ${(props): string => props.theme.forms.placeholder.color};
  }

  /* https://caniuse.com/mdn-css_selectors_-webkit-resizer */
  ::-webkit-resizer {
    background: url('data:image/svg+xml;base64,${(props): string => convertIcon(props.theme.palette.primary)}');
  }
`;
