import React, {FC, useState, RefObject, useEffect, useRef} from 'react';
import styled from 'styled-components';

const statesChain = [
  false,
  'mixed',
  true
];

interface IExtraProps {
  state: boolean | string;
  styleCustom?: string;
}

const Wrapper = styled.div`
  display: inline-block;
`;

const StyledCheckbox = styled.div<IExtraProps>`
  display: inline-block;
  width: 16px;
  height: 16px;
  border: ${(props): string => props.theme.forms.border.style};
  border-radius: ${(props): string => props.theme.forms.border.radius};
  background: ${(props): string => props.theme.palette.white};
  cursor: pointer;
  ${(props) => props.styleCustom && props.styleCustom}
`;

const Icon = styled.svg`
  fill: none;
  stroke: ${(props): string => props.theme.palette.primary};
  stroke-width: 3px;
`;

interface IProps {
  triggerRef?: RefObject<HTMLElement>;
  ariaLabel?: string;
  ariaLabelledBy?: string;
  id?: string;
  onChanged: (val: boolean | string) => void;
  state?: boolean | string;
  isMixed?: boolean;
  styleCustom?: string;
}

export const Checkbox: FC<IProps> = (
  {
    triggerRef,
    id,
    ariaLabel,
    ariaLabelledBy,
    onChanged,
    state = false,
    isMixed= false,
    styleCustom,
  }
): JSX.Element => {

  const checkBoxRef = useRef(null);

  const clickTrigger = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    checkBoxRef?.current?.focus();
    checkBoxRef?.current?.click();
  };

  const mouseDownTrigger = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }

  const nextState = () => {
    if (isMixed) {
      let index = statesChain.indexOf(state);
      index ++;
      if (index >= statesChain.length) {
        index = 0;
      }
      return statesChain[index];
    }
    return !state;
  }

  const handleClick = () => {
    onChanged(nextState());
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if(!/^ $|^Enter$/.test(e.key)) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    onChanged(nextState());
  }

  useEffect(() => {
    const eventTarget = triggerRef?.current;

    if (eventTarget) {
      eventTarget.addEventListener('click', clickTrigger);
      eventTarget.addEventListener('mousedown', mouseDownTrigger);
    }

    return (): void => {
      if (eventTarget) {
        eventTarget.removeEventListener('click', clickTrigger);
        eventTarget.removeEventListener('mousedown', mouseDownTrigger);
      }
    }
  }, [triggerRef]);

  return (
    <StyledCheckbox
      role="checkbox"
      tabIndex="0"
      aria-checked={state}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      state={state}
      {...(id && { id })}
      {...(ariaLabel && { 'aria-label': ariaLabel })}
      {...(ariaLabelledBy && { 'aria-labelledby': ariaLabelledBy })}
      {...(styleCustom && {styleCustom})}
      ref={checkBoxRef}
    >
      <Icon viewBox="0 0 24 24">
        {state ? state === 'mixed' ? (
          <line x1="4" x2="20" y1="12" y2="12" />
        ) : (
          <polyline points="20 6 9 17 4 12" />
        ) : (<></>)}
      </Icon>
    </StyledCheckbox>
  );
};