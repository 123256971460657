import styled from 'styled-components';
import { BaseButton } from './Base';

export const PrimaryButton = styled(BaseButton)`
  color: ${(props) => props.theme.buttons.primary.text.color};
  background: ${(props) => props.theme.buttons.primary.color};
  padding: 0 ${(props) => props.theme.buttons.primary.padding};

  &:hover:not([disabled]) {
    //background-color: ${(props) => props.theme.palette.hoverButtonPrimary};
    background-color: ${({theme}) => theme.themeColors.base[400]};
  }

  &:active {
    // background-color: ${(props) => props.theme.palette.activeButtonPrimary};
    background-color: ${({theme}) => theme.themeColors.base[300]};
  }
`;
